import './App.css';
import Main from './pages/Main'
import './custom.css';

function App() {
  return (
      <div>
        <Main/>
      </div>
  );
}

export default App;
